import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseForm, ModuleKeywords } from '@shared/classes';
import { ViewModeService } from '@shared/services';

@Component({
  selector: 'app-general-record-form',
  templateUrl: './general-record-form.component.html',
  styleUrl: './general-record-form.component.scss'
})
export class GeneralRecordFormComponent extends BaseForm<any> implements OnInit {

  @Input() set setModuleKeyword(value) {
    this.formModuleKeyword = value;
  }

  constructor(
    public viewModeService: ViewModeService,
  ) {
    super(viewModeService, ModuleKeywords.AuthorityDocument);
  }

  ngOnInit(): void { }

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat()
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      description: new FormControl(null),
    });
  }
}
