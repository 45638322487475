<div class="flex flex-row">
  <app-badge-item [status]="data?.type" [styleClass]="'border-noround-right'"></app-badge-item>
  <div
    pTooltip="Count of Records"
    tooltipPosition="top"
    class="px-1 surface-300 border-round-right text-sm font-semibold"
  >
    {{ data?.count }}
  </div>
</div>
