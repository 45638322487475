@for (obj of formObjects; track obj; let i = $index) {
  <ng-container class="grid w-full m-auto">
    @if (obj.active) {
      <div class="col-6">
        @if (fieldType == 'IP' || fieldType == 'IPV4_MASK') {
          <app-number-input
            [label]="obj.label"
            [min]="0"
            [max]="255"
            [placeholder]="obj.label"
            [control]="obj.form"
            [viewMode]="viewMode"
          ></app-number-input>
        } @else if (fieldType == 'MAC' || fieldType == 'IP6') {
          <app-basic-input
            [label]="obj.label"
            [placeholder]="obj.label"
            [control]="obj.form"
            [viewMode]="viewMode"
          ></app-basic-input>
        }
      </div>
    }
  </ng-container>
}
