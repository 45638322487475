import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocaleService } from '@core/services/locale/locale.service';
import { ItemBasePage, ModuleKeywordRootPath } from '@shared/classes';
import { BaseRequestControllerService, DataService, PathResolverService, ToastService, ViewModeService } from '@shared/services';

@Component({
  selector: 'app-general-record-item',
  templateUrl: './general-record-item.component.html',
  styleUrl: './general-record-item.component.scss'
})
export class GeneralRecordItemComponent extends ItemBasePage<any> implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private dataService: DataService,
    private requestService: BaseRequestControllerService<any>,
    private toastService: ToastService,
    private pathResolver: PathResolverService,
    public viewModeService: ViewModeService,
    private router: Router,
    private localeService: LocaleService
  ) {
    super(
      {

      },
      router,
      requestService,
      toastService
    );
    this.subs.sink = this.route.params.subscribe({
      next: (params) => {
        this.itemId = params['id'];
        if (this.itemId) {
          this.moduleKeyword = this.pathResolver.getModuleKeywordByCode(this.itemId);
          this.requestService = this.pathResolver.getDataServiceByModuleKeyword(this.moduleKeyword as any);
          this.routePrefix = ModuleKeywordRootPath[this.moduleKeyword];
        }
        this.SetPageValues({
          breadCrumb: {
            items: [

            ],
          },
          itemId: this.itemId,
          fromType: null,
        });
      },
    });
  }

  ngOnInit(): void { }
}
