<div class="flex md:flex-row flex-row-reverse gap-2 md:flex-nowrap flex-wrap mb-2">
  <div class="lg:w-10 md:w-9 w-full">
    <div class="flex flex-column gap-3 w-full">
      <div class="flex align-items-end gap-3">
        <div style="flex: 0.15">
          <p-skeleton width="100%" height="32px" styleClass="" />
        </div>
        <div style="flex: 0.18">
          <p-skeleton width="100%" height="32px" styleClass="" />
        </div>
        <div style="flex: 0.12">
          <p-skeleton width="100%" height="32px" styleClass="" />
        </div>
        <div style="flex: 0.1">
          <p-skeleton width="100%" height="32px" styleClass="" />
        </div>
        <div style="flex: 0.15">
          <p-skeleton width="100%" height="32px" styleClass="" />
        </div>
        <div style="flex: 0.2">
          <p-skeleton width="100%" height="32px" styleClass="" />
        </div>
      </div>
    </div>
  </div>
  <div class="lg:w-2 md:w-3 w-14rem md:min-w-0 min-w-14rem flex justify-content-end">
    <div class="flex flex-column gap-3 w-full">
      <div class="flex align-items-end gap-3">
        <div style="flex: 1">
          <p-skeleton width="100%" height="32px" styleClass="" />
        </div>
      </div>
    </div>
  </div>
</div>
<div class="flex flex-column gap-3 w-full">
  <div class="flex align-items-end gap-3">
    <div style="flex: 0.5">
      <p-skeleton width="100%" height="18rem" styleClass="" />
    </div>
    <div style="flex: 0.5">
      <p-skeleton width="100%" height="18rem" styleClass="" />
    </div>
  </div>
  <div class="flex align-items-end gap-3">
    <div style="flex: 1">
      <p-skeleton width="100%" height="18rem" styleClass="" />
    </div>
  </div>
</div>
