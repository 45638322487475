import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { DynamicComponentBase } from '@shared/classes/view/DynamicComponentBase';

@Component({
  selector: 'app-multi-network-input',
  templateUrl: './multi-network-input.component.html',
  styleUrls: ['./multi-network-input.component.scss'],
})
export class MultiNetworkInputComponent extends DynamicComponentBase implements OnInit {
  _disbaledBlocksControl: AbstractControl<any>;
  @Input() fieldType: 'IP' | 'IP6' | 'IPV4_MASK' | 'MAC' | any;
  @Input() set disbaledBlocksControl(control: AbstractControl<any>) {
    this._disbaledBlocksControl = control;
  }

  @Input() set disabledList(disabledList: any) {
    this.setForms(disabledList);
    this.checkValidity();
  }

  get disbaledBlocksControl() {
    return this._disbaledBlocksControl;
  }

  formObjects: { label: string; form: AbstractControl<any>; active: boolean }[] = [];

  setForms(dissabledList) {
    this.subs?.sink?.unsubscribe();
    if (!dissabledList) {
      this.control.patchValue(null);
    }
    this.formObjects = [1, 2, 3, 4, 5, 6, 7, 8]?.map((x) => {
      if (!(dissabledList && dissabledList.includes(x))) {
        const list = [...(this.control?.value ?? [])];
        list[x - 1] = '';
        this.control.patchValue(list);
      }
      return {
        label: 'Block ' + x,
        form: new FormControl(null, Validators.compose([Validators.required, ...(this.fieldType == 'MAC' ? [Validators.pattern(/^[0-9A-Fa-f]{2}$/)] : this.fieldType == 'IP6' ? [Validators.pattern(/^[0-9A-Fa-f]{1,4}$/)] : [])])),
        active: false,
      };
    });

    dissabledList?.forEach((element) => {
      this.formObjects[element - 1].active = true;
      this.subs.sink = this.formObjects[element - 1]?.form?.valueChanges.subscribe((x) => {
        const list = [...this.control?.value];
        list[element - 1] = x;
        this.control.patchValue(list);
        this.checkValidity();
      });
    });

    this.control.value.forEach((element, i) => {
      if (element) {
        this.formObjects[i].form.patchValue(element);
      }
    });
  }

  checkValidity() {
    for (let i = 0; i < this.disbaledBlocksControl?.value?.length; i++) {
      const element = this.disbaledBlocksControl.value[i];
      if (!this.formObjects[element - 1].form.valid) {
        this.formObjects[element - 1].form.markAsDirty();
        this.control.markAsDirty();
        this.control.setErrors({ dsd: 'Error' });
        return;
      } else {
        this.formObjects[element - 1].form.markAsPristine();
      }
    }
    this.control.setErrors(null);
    this.control.markAsPristine();
  }

  constructor() {
    super();
  }

  ngOnInit(): void { }
  setInputOptions() { }
  onFocusChange(event) { }
}
