import {
  AfterViewInit,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  DynamicComponentBase,
  FilterItem,
  IAction,
  ITableLoadEvent,
  ITablePageable,
  OfflineConfig,
  RequestHandlerOptions,
  TargetTypeEnum,
  getModuleKeywordByCode,
  humanizeCasedString,
  isNullObj
} from '@shared/classes';
import { tableComponent } from '@shared/pages/table/table.component';
import { PathResolverService } from '@shared/services';
import { cloneDeep } from 'lodash-es';
import { NgxPermissionsService } from 'ngx-permissions';
import { MenuItem } from 'primeng/api';
/*
=================================================================
IMPORTANT NOTE
when using option value that is not code, only use 1 target type 
because it cannot fetch the module service/keyword without
code which is important to set selected rows for each target type
=================================================================
*/
@Component({
  selector: 'app-table-code-selector',
  templateUrl: './table-code-selector.component.html',
  styleUrl: './table-code-selector.component.scss',
})
export class TableCodeSelectorComponent extends DynamicComponentBase implements OnInit, AfterViewInit {
  tablesMap = {};
  menuItems: MenuItem[] = [];
  @ViewChild(tableComponent) tableComponent!: tableComponent;
  @Input() selectionMode: 'single' | 'multiple' | undefined | null = 'multiple';
  showSearchField = true;
  inited = false;
  viewType: 'selector' | 'reorder' | 'add' | 'edit' = 'selector';
  _currentItem: MenuItem;
  searchKeywords = {};
  set currentItem(value: MenuItem) {
    // this.showSearchField = false;
    // setTimeout(() => { this.showSearchField = true; })
    if (this._currentItem)
      this.searchKeywords[this._currentItem?.id] = this.tableComponent?.globalFilter?.nativeElement?.value ?? '';

    if (this.tableComponent?.globalFilter)
      this.tableComponent.globalFilter.nativeElement.value = this.searchKeywords[value?.id] ?? '';
    this._currentItem = value;
    if (
      this.currentItem?.id &&
      !this.tablesMap?.[this.currentItem?.id]?.loading &&
      (this.forcedRefresh || !this.tablesMap?.[this.currentItem.id]?.data?.length)
    ) {
      this.fetchTableData(this.currentItem?.id, true, new ITableLoadEvent());
    }
    this.initSelectedValue = isNullObj(this.initSelectedValue) ? (this.selectionMode == 'multiple' ? [] : null) : (this.selectionMode == 'multiple' ? [...(Array.isArray(this.initSelectedValue) ? this.initSelectedValue : [])] : this.initSelectedValue);
  }
  get currentItem() {
    return this._currentItem;
  }
  pageInfo: ITablePageable = new ITablePageable();

  _inlineControl: FormControl<any>;
  @Input() set inlineControl(control: FormControl<any>) {
    this._inlineControl = control;
  }
  get inlineControl() {
    return this._inlineControl;
  }

  orderControl = new FormControl(null);
  selectorControl = new FormControl(null);

  _initValue: any;
  @Input() set initValue(value: any) {
    this._initValue = value;
  }
  get initValue() {
    return this._initValue;
  }

  @Input() optionValue: any;
  @Input() dataKey: any;
  @Input() filters: FilterItem[] = [];
  @Input() searchUrl: string = null;
  @Input() customSort: string[] = [];
  @Input() forcedRefresh;
  @Input() dialogVisable;
  @Input() customObjectFields;
  @Input() additionalProjectionFields: string[] = [];

  @Input() set targetTypes(types: TargetTypeEnum[]) {
    this._targetTypes = types;

    this.getOptions();
  }
  @Input() inlineMode = false;
  @Input() listBoxMode;
  @Output() onInlineChange: EventEmitter<any> = new EventEmitter();
  @ContentChild('okButtonTemplateRef') okButtonTemplate: TemplateRef<any>;

  valueChangedFlag = false;
  private _targetTypes: TargetTypeEnum[];
  get targetTypes() {
    return this._targetTypes;
  }

  offlineConfig: OfflineConfig = new OfflineConfig();

  initSelectedValue: any = [];
  allSelectedItems = [];

  clearAction: IAction = {
    id: 1,
    label: 'Clear',
    buttonType: 'button',
    command: this.clear.bind(this),
    icon: 'pi pi-times',
  };

  actions: IAction[] = [];

  reorderAction: IAction = {
    id: 2,
    label: 'Reorder',
    buttonType: 'button',
    command: this.switchView.bind(this, 'reorder'),
  };

  selectorAction: IAction = {
    id: 3,
    label: 'Selector',
    buttonType: 'button',
    command: this.switchView.bind(this, 'selector'),
  };

  addAction: IAction = {
    id: 3,
    label: 'Add',
    buttonType: 'button',
    command: this.switchView.bind(this, 'add'),
  };

  editAction: IAction = {
    id: 4,
    label: 'edit',
    buttonType: 'button',
    command: this.switchView.bind(this, 'edit'),
  };
  readonly defaultSort = ['creationDate,desc'];
  constructor(
    private pathResolverService: PathResolverService,
    private permissionService: NgxPermissionsService
  ) {
    super();
    // this.getOptions = this.getOptions.bind(this);

    // this.offlineConfig.showActionBar = false;
  }
  ngOnInit(): void {
    this.dataKey = this.dataKey ?? 'code';

    this.initValue = this.inlineControl?.value;
    this.setValue();
    // if (!this.inlineControl?.value?.length) {
    //   this.clear(false);
    //   if (!this.dialogVisable && this.forcedRefresh && this.currentItem?.id) {
    //     this.fetchTableData(this.currentItem.id, this.tablesMap[this.currentItem.id]?.tableEvent);
    //   }
    // }

    this.subs.sink = this.orderControl.valueChanges.subscribe((value) => {
      const key = this.optionValue == undefined ? this.dataKey : this.optionValue;
      if (this.selectionMode == 'multiple') {
        this.inlineControl.patchValue(
          value.map((x) =>
            this.optionValue
              ? x[key]
              : this.customObjectFields
                ? this.customObjectFields.reduce((acc, key) => {
                  if (x.hasOwnProperty(key)) {
                    acc[key] = x[key];
                  }
                  return acc;
                }, {})
                : x
          )
        );
      }
    });

    this.subs.sink = this.selectorControl.valueChanges.subscribe((x) => {
      this.checkOrderAndPatch(x);
    })

    this.subs.sink = this.inlineControl.valueChanges.subscribe((x) => {
      this.initValue = x;
      if (!this.valueChangedFlag) {
        this.valueChangedFlag = false;
        return;
      }
      this.setValue();
      if (this.selectionMode ? !x : !x?.length) {
        this.clear(false);
        if (!this.dialogVisable && this.forcedRefresh && this.currentItem?.id) {
          this.fetchTableData(this.currentItem.id, true, this.tablesMap[this.currentItem.id]?.tableEvent);
        }
      }
    });
    // this.setControl();
  }

  ngAfterViewInit(): void { this.switchView('selector'); }

  setInputOptions(): void { }

  async getOptions(filters: FilterItem[] = []) {
    this.setTargetTypes();
    // if (this._initValue)
    //   this.setValue();
  }

  setTargetTypes() {
    this.tablesMap = {};
    this.menuItems = [];
    this.clear();
    this.targetTypes.forEach((typeItem) => {
      const service = this.pathResolverService.getDataServiceByTargetType(typeItem);
      const mappingService = this.pathResolverService.getMappingServiceByTargetType(typeItem);
      this.tablesMap[typeItem.toString()] = {
        name: typeItem,
        selected: 0,
        service: service,
        mappingService: mappingService,
        cols: [...mappingService.tableFields],
        tableEvent: new ITableLoadEvent(),
      };
      this.menuItems.push({
        label: humanizeCasedString(typeItem.toString()),
        id: typeItem.toString(),
        command: () => { },
      });
    });
  }

  setValue() {
    const key = this.optionValue == undefined ? this.dataKey : this.optionValue;
    Object.entries(this.tablesMap).forEach((x) => {
      this.tablesMap[x[0]].selected = 0;
      if (this.selectionMode == 'single') {
        this.tablesMap[x[0]].selectedItems = null;

      } else {
        this.tablesMap[x[0]].selectedItems = [];
      }
    });
    if (this._initValue) {
      if (this.selectionMode == 'multiple') {
        this.initSelectedValue = this._initValue?.length
          ? this._initValue?.map((item) => {
            if (key != 'code' && this.targetTypes?.length) {
              this.tablesMap[this.targetTypes[0]].selected = (this.tablesMap[this.targetTypes[0]]?.selected ?? 0) + 1;
              this.tablesMap[this.targetTypes[0]].selectedItems = [
                ...(this.tablesMap[this.targetTypes[0]]?.selectedItems ?? []),
                this.optionValue == undefined ? item : { [key]: item },
              ];
            } else if (this.dataKey == 'code') {
              const module = getModuleKeywordByCode(this.optionValue == undefined ? item[this.dataKey] : item);
              if (module) {
                this.tablesMap[module].selected = (this.tablesMap[module]?.selected ?? 0) + 1;
                this.tablesMap[module].selectedItems = [
                  ...(this.tablesMap[module]?.selectedItems ?? []),
                  this.optionValue == undefined ? item : { [key]: item },
                ];
              }
            }
            return this.optionValue == undefined ? item : { [this.dataKey]: item };
          })
          : /*{ [this.dataKey]: this._initValue }*/[];
        this.allSelectedItems = cloneDeep(this.initSelectedValue);
        this.orderControl.patchValue(this.allSelectedItems, { emitEvent: false });
        this.clearAction.label = 'Clear ' + (this.allSelectedItems?.length ?? 0) + '';
      } else {
        if (this._initValue) {
          if (key != 'code' && this.targetTypes?.length) {
            this.tablesMap[this.targetTypes[0]].selected = (this.tablesMap[this.targetTypes[0]]?.selected ?? 0) + 1;
            this.tablesMap[this.targetTypes[0]].selectedItems =
              this.optionValue == undefined ? this._initValue : { [key]: this._initValue };
          } else if (this.dataKey == 'code') {
            const module = getModuleKeywordByCode(this.optionValue == undefined ? this._initValue[this.dataKey] : this._initValue);
            if (module) {
              this.tablesMap[module].selected = (this.tablesMap[module]?.selected ?? 0) + 1;
              this.tablesMap[module].selectedItems = this.optionValue == undefined ? this._initValue : { [key]: this._initValue };
            }
          }
          this.initSelectedValue = this.optionValue == undefined ? this._initValue : { [this.dataKey]: this._initValue };
        }
        else {
          this.initSelectedValue = { [this.dataKey]: this._initValue };
        }
        this.allSelectedItems = [cloneDeep(this.initSelectedValue)];
        this.clearAction.label = 'Clear ' + (this.allSelectedItems?.length ?? 0) + '';
      }
    }
  }

  fetchTableData(currentId, inited, tableEvent: ITableLoadEvent) {
    if (!inited) {
      return;
    }
    const service = this.tablesMap[currentId.toString()].service;
    this.tablesMap[currentId].tableEvent = { ...tableEvent };
    this.tablesMap[currentId].loading = true;

    const sort = [...(this.customSort ?? []), ...(this.tablesMap?.[currentId].tableEvent?.pageInfo?.sort ?? [])];
    const filters = [...(this.filters ?? []), ...(this.tablesMap?.[currentId]?.tableEvent?.filters ?? [])];

    const exec = {
      next: (res: any) => {
        this.inited = true;
        this.tablesMap[currentId].data = res.content;
        this.tablesMap[currentId].tableEvent.pageInfo.totalElements = res?.totalElements ?? 0;
        this.tablesMap[currentId].loading = false;
      },
      error: (error) => {
        this.tablesMap[currentId].loading = false;

        // this.isLoading = false;
      },
      complete: () => {
        this.tablesMap[currentId].loading = false;
      },
    };
    const search =
      tableEvent?.textSearch; /*? { ...tableEvent?.textSearch, search: trim(tableEvent?.textSearch?.search, ' ') } : undefined*/
    if (this.searchUrl) {
      service
        .search(
          { ...tableEvent.pageInfo.pagination, sort: sort?.length > 0 ? sort : this.defaultSort },
          {
            projectionFields: isNullObj(this.additionalProjectionFields)
              ? service.projectionFields
              : [...service.projectionFields, ...this.additionalProjectionFields],
            filters: filters?.length ? filters : null,
            textSearch: search,
          },
          new RequestHandlerOptions(),
          this.searchUrl
        )
        .subscribe(exec);
    } else {
      service
        .search(
          { ...tableEvent.pageInfo.pagination, sort: sort?.length > 0 ? sort : this.defaultSort },
          {
            projectionFields: isNullObj(this.additionalProjectionFields)
              ? service.projectionFields
              : [...service.projectionFields, ...this.additionalProjectionFields],
            filters: filters?.length ? filters : null,
            textSearch: search,
          }
        )
        .subscribe(exec);
    }
  }

  onRowSelected(value) {
    let newItems = [];
    let remainingItems = [];
    const key = this.optionValue == undefined ? this.dataKey : this.optionValue;
    if (this.selectionMode == 'multiple') {
      value.forEach((element) => {
        const module = getModuleKeywordByCode(key ? element[key] : element);
        if (key != 'code' && this.currentItem?.id) {
          newItems.push(element);
        } else if (module == this.currentItem.id) {
          newItems.push(element);
        } else {
          remainingItems.push(element);
        }
      });
      this.tablesMap[this.currentItem.id].selected = newItems?.length ?? 0;
      this.tablesMap[this.currentItem.id].selectedItems = newItems;
      let listOfSlectedItems = [];
      Object.values(this.tablesMap).forEach((x) => {
        const item = x as any;
        listOfSlectedItems = [...listOfSlectedItems, ...(item.selectedItems ?? [])];
      });
      this.allSelectedItems = [...listOfSlectedItems];
      this.initSelectedValue = [...newItems, ...remainingItems];
      this.clearAction.label = 'Clear (' + (this.allSelectedItems?.length ?? 0) + ')';
      if (this.inlineControl) {
        this.valueChangedFlag = true;
        this.selectorControl.patchValue(listOfSlectedItems);
      }
    } else {
      Object.entries(this.tablesMap).forEach((x) => {
        this.tablesMap[x[0]].selected = 0;
        this.tablesMap[x[0]].selectedItems = null;
      });
      this.tablesMap[this.currentItem.id].selected = value ? 1 : 0;
      this.tablesMap[this.currentItem.id].selectedItems = value;
      this.allSelectedItems = value ? [value] : [];
      this.menuItems = [...this.menuItems];
      this.clearAction.label = 'Clear ' + (value ? 1 : 0);
      if (this.inlineControl) {
        this.valueChangedFlag = true;
        this.inlineControl.patchValue(
          this.optionValue
            ? value[key]
            : this.customObjectFields
              ? this.customObjectFields.reduce((acc, key) => {
                if (value.hasOwnProperty(key)) {
                  acc[key] = value[key];
                }
                return acc;
              }, {})
              : value
        );
      }
    }
    // if (this.inlineMode) {
    //   this.onInlineChange.emit();
    // }
  }

  checkOrderAndPatch(value) {
    if (value) {
      const orderList = this.orderControl.value ?? [];
      const orderMap = {};
      if (orderList) {
        orderList.forEach(element => {
          if (element?.code) {
            orderMap[element?.code] = element;
          }
        });
      }
      const selectorMap = {};
      value.forEach(element => {
        if (element?.code) {
          selectorMap[element?.code] = element;
          if (!orderMap[element?.code]) {
            orderList.push(element);
          }
        }
      });

      let newList = [...orderList];
      if (orderList && value.length != orderList.length) {
        newList = orderList.filter(element => selectorMap[element?.code]);
      }
      this.orderControl.patchValue(newList);
    }
  }

  onClearSelection(event) {
    this.onRowSelected([]);
  }

  clear(withPatching = true) {
    Object.entries(this.tablesMap).forEach((x) => {
      this.tablesMap[x[0]].selected = 0;
      if (this.selectionMode == 'single') {
        this.tablesMap[x[0]].selectedItems = null;

      } else {
        this.tablesMap[x[0]].selectedItems = [];
      }
    });
    this.initSelectedValue = [];
    this.allSelectedItems = [];
    this.clearAction.label = 'Clear (' + (this.allSelectedItems?.length ?? 0) + ')';
    if (withPatching) {
      this.valueChangedFlag = true;
      if (this.selectionMode == 'single') {
        this.inlineControl?.patchValue(null);
      } else {
        this.inlineControl?.patchValue([]);

      }
      // if (this.inlineMode) {
      //   this.onInlineChange.emit();
      // }
    }
  }

  switchView(view) {
    this.viewType = view;
    this.rearrengeActionsList();
  }

  rearrengeActionsList() {
    this.actions = [
      ...(this.viewType != 'reorder' ? [this.reorderAction] : []),
      ...(this.viewType != 'selector' ? [this.selectorAction] : []),
      ...(this.viewType != 'add' ? [this.addAction] : []),
      ...(this.viewType != 'edit' ? [this.editAction] : [])
    ];
  }
}
