<app-input-view-switch
  [showLabelInViewMode]="showLabelInViewMode"
  [mode]="'create'"
  [data]="valueObject"
  [options]="{
    viewDataType: viewDataType,
    viewOptions: viewOptions,
    optionValue: optionValue,
    progressColor: progressColor,
    useRawValue: useRawValue,
  }"
  [dataType]="dataType"
  [label]="label"
  [keepValueCallback]="setFieldResetable.bind(this)"
  [resetFieldCheckVisable]="resetFieldCheckVisable"
  [resetableChecked]="resetable"
>
  <span
    class="flex flex-row md:flex-nowrap flex-wrap w-full align-items-start"
    [class]="viewMode == 'view' ? 'gap-2' : 'gap-1'"
  >
    @if (!floatLabel) {
      @if (label && !instanceInFormRepeater) {
        <label [for]="name || label" class="md:w-12rem md:max-w-12rem md:min-w-12rem w-full input-field-label"
          >{{ label }}
          @if (isControlRequired) {
            <span class="text-red-500"> *</span>
          }
          @if (resetFieldCheckVisable) {
            <br />
            <app-reset-switch-input
              [checked]="!resetable"
              (onChange)="setFieldResetable($event)"
            ></app-reset-switch-input>
          }
        </label>
      }
    }
    <app-planned-progress-bar-view
      class="w-full"
      [data]="valueObject"
      [viewDataType]="viewDataType"
      [viewOptions]="viewOptions"
      [optionValue]="optionValue"
      [useRawValue]="useRawValue"
      [progressColor]="progressColor"
    ></app-planned-progress-bar-view>
  </span>
  @if (control?.hasError('diffError')) {
    <small class="p-error p-invalid">{{ label }} {{ control?.errors?.diffError }}</small>
  }
</app-input-view-switch>
