<span
  class="p-inputgroup flex flex-row relative hide-selection flex flex-row md:flex-nowrap flex-wrap w-full align-items-start"
  [class.p-float-label]="floatLabel"
>
  <ng-container *ngIf="!floatLabel; then labelTemplate"></ng-container>

  <div
    class="flex flex-column gap-3 min-w-0 max-w-calc-full"
    [class]="inlineMode ? '' : 'w-full'"
    [class.md:max-w-calc-full-12rem]="label && !floatLabel && !listBoxMode"
  >
    <div class="flex gap-2">
      <p-tabMenu
        [(activeItem)]="currentItem"
        [model]="menuItems"
        [activeItem]="menuItems[0]"
        [scrollable]="true"
        class="w-full"
      >
        <ng-template pTemplate="item" let-item>
          <a pRipple class="flex align-items-center gap-3 p-menuitem-link">
            <span class="font-bold white-space-nowrap"> {{ item.label }} </span>
            @if (selectionMode == 'multiple') {
              <p-badge [value]="this.tablesMap?.[item?.id]?.selectedItems?.length ?? 0" />
            } @else {
              @if (this.tablesMap?.[item?.id]?.selectedItems) {
                <p-badge [value]="'1'" />
              }
            }
          </a>
        </ng-template>
      </p-tabMenu>
      <div class="flex gap-2 align-items-center text-nowrap">
        <!-- <span class="text-primary cursor-pointer hover:text-primary-700" (click)="clear()">
              @if (selectionMode == 'single') {
                <i class="pi pi-times"></i>
              } @else {
                Clear {{ allSelectedItems?.length ?? 0 }}
              }
            </span> -->
        <app-button-list
          class="align-self-center"
          [listType]="'split'"
          [actionList]="actions"
          [appendTo]="'body'"
        ></app-button-list>
        @if (selectionMode != 'single' && currentItem?.id && viewMode != 'view' && allSelectedItems?.length) {
          <app-button [action]="clearAction"></app-button>
        }
      </div>
    </div>
    @switch (viewType) {
      @case ('selector') {
        @if (currentItem?.id) {
          <app-table
            style="display: block; height: 55vh; max-height: 55vh; min-height: 55vh"
            [useAutoUpdate]="false"
            [selectionMode]="selectionMode"
            [offlineConfig]="offlineConfig"
            [inputData]="tablesMap[currentItem.id].data"
            [pageInfo]="tablesMap[currentItem.id].tableEvent?.pageInfo"
            [isLoading]="tablesMap[currentItem.id].loading"
            [cols]="tablesMap[currentItem.id].cols"
            (filtersChanged)="fetchTableData(currentItem.id, inited, $event)"
            (onRowSelection)="onRowSelected($event)"
            (onClearSelectionAndFilters)="onClearSelection($event)"
            [initSelectedItems]="tablesMap[currentItem.id].selectedItems"
            [dataKey]="optionValue == undefined ? dataKey : optionValue"
            [showSearchField]="showSearchField"
          ></app-table>
        } @else {
          <div style="display: block; height: 55vh; max-height: 55vh; min-height: 55vh">
            <div class="mb-4 w-full h-full text-center">
              @if (currentItem?.id) {
                <i class="pi pi-spin pi-spinner text-400"></i>
              } @else {
                <p>No Target Type Selected</p>
              }
            </div>
          </div>
        }
      }
      @case ('reorder') {
        <app-reorder-selector [control]="orderControl" [items]="orderControl?.value ?? []"></app-reorder-selector>
      }
    }
    <div
      class="w-full flex flex-row align-items-center justify-content-end gap-3"
      [class]="label || listBoxMode ? 'mb-3' : ''"
    >
      @if (selectionMode == 'single' && allSelectedItems?.length) {
        <i (click)="clear()" class="pi pi-times cursor-pointer" style="color: red"></i>
        <app-code-navigation [data]="allSelectedItems[0].code"></app-code-navigation>
      }
      @if (okButtonTemplate) {
        <ng-container *ngTemplateOutlet="okButtonTemplate"></ng-container>
      }
    </div>
  </div>
  <ng-container *ngIf="floatLabel; then labelTemplate"></ng-container>
</span>
<ng-template #labelTemplate>
  @if (initValue) {
    <label
      class="md:w-12rem md:max-w-12rem md:min-w-12rem w-full mr-1 input-field-label"
      *ngIf="label && !instanceInFormRepeater"
      >{{ label }}<span *ngIf="isControlRequired" class="text-red-500"> *</span>
      @if (resetFieldCheckVisable) {
        <br />
        <app-reset-switch-input [checked]="!resetable" (onChange)="setFieldResetable($event)"></app-reset-switch-input>
      }
    </label>
  }
</ng-template>
<!-- [metaKeySelection] ="true" -->
