<!-- <p-toolbar styleClass="mb-8">
    <div class="p-toolbar-group-left">
    </div>

    <div class="p-toolbar-group-right">
        <p-button icon="pi pi-filter" (click)="onCreateReq()" label="Apply Filter" styleClass="p-button"></p-button>
    </div>
</p-toolbar> -->
<div class="flex flex-row md:flex-nowrap flex-wrap gap-1 w-full align-items-start">
  <span class="m-0 md:w-12rem md:max-w-12rem md:min-w-12rem align-self-start w-full input-field-label"> Roles </span>
  <p-pickList
    #pickList
    [source]="sourceElements"
    [target]="targetElements"
    sourceHeader="Available Roles"
    targetHeader="Selected Roles"
    [dragdrop]="true"
    [responsive]="true"
    [sourceStyle]="{ height: '30rem' }"
    [targetStyle]="{ height: '30rem' }"
    filterBy="name"
    sourceFilterPlaceholder="Search by name"
    targetFilterPlaceholder="Search by name"
    [showTargetFilter]="showTargetFilter"
    (onMoveAllToSource)="onChange()"
    (onMoveAllToTarget)="onChange()"
    (onMoveToSource)="onChange()"
    (onMoveToTarget)="onChange()"
    class="w-full app-picklist"
    [class.pick-list-view-mode]="viewMode == 'view'"
    [disabled]="viewMode == 'view'"
  >
    <ng-template pTemplate="sourceFilter" let-options="options">
      <div class="flex flex-row w-full">
        <div class="flex flex-1">
          <div class="p-picklist-filter w-full">
            <input
              appMaxCharacterValidator
              [(ngModel)]="sourceFilterValue"
              type="text"
              (keyup)="options.filter($any($event.target).value)"
              role="textbox"
              class="p-picklist-filter-input p-inputtext p-component border-noround border-round-left"
              placeholder="Search by name"
            />
            <ng-container *ngIf="sourceFilterValue; else elseTemplate">
              <span class="p-picklist-filter-icon pi pi-times" (click)="options.reset(); sourceFilterValue = ''"></span>
            </ng-container>
            <ng-template #elseTemplate>
              <span [class]="'p-picklist-filter-icon ' + (loading ? 'pi pi-spin pi-spinner' : 'pi pi-search')"></span>
            </ng-template>
          </div>
        </div>
        <!-- <div class="flex">
                <p-button icon="pi pi-filter" (click)="onCreateReq()" label=""
                    [styleClass]="'p-button border-noround ' + (showCategoriesSelector ? '' : ' border-round-right')"></p-button>
            </div> -->
        <!-- <div *ngIf="showCategoriesSelector" class="flex">
                <app-button
                pTooltip="Categories"
                tooltipPosition="top"
                (onClickAction)="op.toggle($event)"
                [action]="{
                    id: 2,
                    label: '',
                    icon: 'pi pi-table',
                    iconPos: 'left',
                    buttonStyle: 'raised',
                    color:'primary',
                    buttonClass:'border-noround border-round-right'
                }"
            ></app-button>
            </div> -->
      </div>
    </ng-template>
    <ng-template pTemplate="targetFilter" let-options="options">
      <div class="flex flex-row w-full">
        <div class="flex flex-1">
          <div class="p-picklist-filter w-full">
            <input
              appMaxCharacterValidator
              [(ngModel)]="sourceFilterValue"
              type="text"
              (keyup)="options.filter($any($event.target).value)"
              role="textbox"
              class="p-picklist-filter-input p-inputtext p-component border-noround border-round-left"
              placeholder="Search by name"
            />
            <ng-container *ngIf="sourceFilterValue; else elseTemplate">
              <span class="p-picklist-filter-icon pi pi-times" (click)="options.reset(); sourceFilterValue = ''"></span>
            </ng-container>
            <ng-template #elseTemplate>
              <span [class]="'p-picklist-filter-icon ' + (loading ? 'pi pi-spin pi-spinner' : 'pi pi-search')"></span>
            </ng-template>
          </div>
        </div>
        <!-- <div class="flex">
                <p-button icon="pi pi-filter" (click)="onCreateReq()" label=""
                    [styleClass]="'p-button border-noround ' + (showCategoriesSelector ? '' : ' border-round-right')"></p-button>
            </div> -->
        <!-- <div *ngIf="showCategoriesSelector" class="flex">
                <app-button
                pTooltip="Categories"
                tooltipPosition="top"
                (onClickAction)="op.toggle($event)"
                [action]="{
                    id: 2,
                    label: '',
                    icon: 'pi pi-table',
                    iconPos: 'left',
                    buttonStyle: 'raised',
                    color:'primary',
                    buttonClass:'border-noround border-round-right'
                }"
            ></app-button>
            </div> -->
      </div>
    </ng-template>
    <ng-template let-product pTemplate="item">
      <div class="product-item">
        <div class="product-list-detail align-items-center flex gap-2">
          <app-badge-item
            *ngIf="product?.code"
            [styleClass]="'text-xs'"
            [status]="product?.recordStatus || 'gray'"
            [text]="product.code"
          ></app-badge-item>
          <span class="product-category">{{ product?.name }} </span>
        </div>
      </div>
    </ng-template>
  </p-pickList>
</div>
<p-overlayPanel #op [showCloseIcon]="true">
  <ng-template pTemplate>
    <p-listbox
      [options]="listOfCategories"
      [checkbox]="true"
      [filter]="true"
      [multiple]="true"
      [formControl]="categoriesControl"
      optionLabel="label"
      [optionValue]="'value'"
      [style]="{ width: '15rem' }"
    >
      <ng-template let-item pTemplate="item">
        <app-badge-item [status]="item.value" [text]="item.label"></app-badge-item>
      </ng-template>
    </p-listbox>
  </ng-template>
</p-overlayPanel>
<!-- <div class="card">
    <p-dataView #dv [value]="targetElements">
        <ng-template let-product pTemplate="listItem">
            <div class="col-12">
                <div class="product-item">
                    <div class="product-list-detail align-items-center flex gap-2">
                        <app-badge-item *ngIf="product?.code" [styleClass]="'text-xs'" [status]="product?.recordStatus || 'gray'" [text]="product.code"></app-badge-item>
                        <span class="product-category">{{product?.name}} </span>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-dataView>
</div> -->
