<app-input-view-switch
  [showLabelInViewMode]="showLabelInViewMode"
  [mode]="viewMode"
  [data]="data || control?.value"
  [dataType]="dataType"
  [label]="label"
  [keepValueCallback]="setFieldResetable.bind(this)"
  [resetFieldCheckVisable]="resetFieldCheckVisable"
  [resetableChecked]="resetable"
>
  <div class="field" [class.mb-1]="control?.dirty && this.control.errors">
    <span
      class="flex flex-row md:flex-nowrap flex-wrap gap-1 w-full align-items-start"
      [class.p-float-label]="floatLabel"
      [class.p-input-icon-left]="leftIcon"
      [class.p-input-icon-right]="rightIcon"
    >
      @if (!floatLabel) {
        @if (label && !instanceInFormRepeater) {
          <label [for]="name || label" class="md:w-12rem md:max-w-12rem md:min-w-12rem w-full input-field-label"
            >{{ label }}
            @if (isControlRequired) {
              <span class="text-red-500"> *</span>
            }
            @if (resetFieldCheckVisable) {
              <br />
              <app-reset-switch-input
                [checked]="!resetable"
                (onChange)="setFieldResetable($event)"
              ></app-reset-switch-input>
            }
          </label>
        }
      }
      @if (leftIcon) {
        <i [class]="' pi ' + leftIcon"></i>
      }
      @if (rightIcon) {
        <i [class]="' pi ' + rightIcon"></i>
      }
      <input
        appMaxCharacterValidator
        pInputText
        class="input-field w-full"
        [type]="type"
        [name]="name || label"
        [id]="name || label"
        [value]="value"
        (blur)="touched()"
        (input)="changed($any($event)?.target?.value)"
        [placeholder]="placeholder"
        [readOnly]="readOnly"
        [disabled]="isDisabled"
      />
      @if (floatLabel) {
        @if (label && !instanceInFormRepeater) {
          <label [for]="name || label" class="md:w-12rem md:max-w-12rem md:min-w-12rem w-full input-field-label"
            >{{ label }}
            @if (isControlRequired) {
              <span class="text-red-500"> *</span>
            }
            @if (resetFieldCheckVisable) {
              <br />
              <app-reset-switch-input
                [checked]="!resetable"
                (onChange)="setFieldResetable($event)"
              ></app-reset-switch-input>
            }
          </label>
        }
      }
    </span>
    @if (control?.dirty) {
      @if (control?.hasError('required')) {
        <small class="p-error p-invalid">{{ label }} {{ 'is Required' }}</small>
      }
      @if (control?.hasError('email')) {
        <small class="p-error p-invalid">Enter a Valid Email</small>
      }
      @if (control?.hasError('pattern')) {
        <small class="p-error p-invalid">{{ label }} {{ patternError }}</small>
      }
      @if (control?.hasError('minlength')) {
        <small class="p-error p-invalid">
          {{ label }} must have a minimum length of {{ control?.errors?.minlength?.requiredLength }}
        </small>
      }
      @if (control?.hasError('maxlength')) {
        <small class="p-error p-invalid">
          {{ label }} must have a maximum length of {{ control?.errors?.maxlength?.requiredLength }}
        </small>
      }
      @if (control?.hasError('min')) {
        <small class="p-error p-invalid">
          {{ label }} must be greater than or equal to {{ control?.errors?.min?.min }}
        </small>
      }
      @if (control?.hasError('max')) {
        <small class="p-error p-invalid">
          {{ label }} must be less than or equal to {{ control?.errors?.max?.max }}
        </small>
      }
      @if (customError && control?.invalid) {
        <small class="p-error p-invalid">{{ customErrorLabel }}</small>
      }
    }
    @if (helpText) {
      <small class="text-500 block">{{ helpText }}</small>
    }
  </div>
</app-input-view-switch>
