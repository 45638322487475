import { Component, Input, OnInit } from '@angular/core';
import { BaseViewItem } from '@shared/classes';

@Component({
  selector: 'app-code-navigation-list',
  templateUrl: './code-navigation-list.component.html',
  styleUrl: './code-navigation-list.component.scss'
})
export class CodeNavigationListComponent extends BaseViewItem<any>
  implements OnInit {
  @Input() showModeIcon = false;

  constructor() {
    super();
  }

  ngOnInit(): void { }
  codesList: any[] = [];
  onSetData(): void {
    this.codesList = [];
    if (Array.isArray(this.data)) {
      this.data.forEach((code) => {
        this.codesList.push(code);
      });
    } else {
      this.codesList.push(this.data);
    }
  }
}