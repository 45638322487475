<!-- <div class="w-full">
    <input appMaxCharacterValidator type="text" pTextInput
        [(ngModel)]="selectedFolderPath"
        readonly>
</div> -->
<app-input-view-switch
  [showLabelInViewMode]="showLabelInViewMode"
  [mode]="viewMode"
  [data]="data?.code || control?.value?.code || control?.value || control?.getRawValue()"
  [dataType]="dataType"
  [label]="label"
>
  <ng-container *ngIf="inlineMode; then inlineTemplate; else editTemplate"></ng-container>
  <!-- <ng-container *ngIf="viewMode == 'create'; then (listBoxMode ? inlineTemplate : selectorTemplate); else editTemplate"></ng-container> -->
</app-input-view-switch>
<ng-template #inlineTemplate>
  <p-tree
    [value]="folders"
    [lazy]="true"
    (onNodeExpand)="loadChildFolders($event)"
    (contextMenuSelect)="handleContextMenu($event)"
    selectionMode="single"
    [(selection)]="selectedFile"
    [contextMenu]="cm"
    [loading]="loading"
    (onNodeSelect)="nodeSelect($event)"
    (onNodeUnselect)="nodeUnselect($event)"
  >
    <ng-template let-node pTemplate="default">
      <span class="line-clamp-1-ellipsis">{{ node.label }}</span>
      <app-button class="add-button" [action]="addFolderAction" [commandData]="node"></app-button>
      <!-- <span *ngIf="node.children && node.children.length === 0"
            class="loading-icon pi pi-spin pi-spinner"></span> -->
    </ng-template>
  </p-tree>
</ng-template>

<p-contextMenu [model]="contextMenuItems" #cm></p-contextMenu>
<p-dialog
  header="Folder Name"
  [modal]="true"
  [style]="{ width: '40vw' }"
  [draggable]="false"
  [resizable]="true"
  [(visible)]="addFolderModal"
>
  <app-folder-bucket-form *ngIf="addFolderModal" (formSubmitSave)="onSubmit($event)"> </app-folder-bucket-form>
</p-dialog>
<ng-template #editTemplate>
  <div class="field" [class.mb-1]="editModalControl?.dirty && this.editModalControl.errors">
    <span
      class="p-inputgroup flex flex-row relative hide-selection flex flex-row md:flex-nowrap flex-wrap w-full align-items-start"
      [class.p-float-label]="floatLabel"
    >
      <ng-container *ngIf="!floatLabel; then labelTemplate"></ng-container>
      <input
        appMaxCharacterValidator
        [type]="'text'"
        pInputText
        readonly
        [formControl]="editModalControl"
        [placeholder]="label"
        (focus)="onFocusChange($event)"
        class="w-full border-round-left cursor-pointer"
        (click)="openEditModal()"
        (keydown)="onEditModalInputKeyDown($event)"
        [style]="'color:transparent; ' + ('height: max(32px,' + '2.4' + 'rem); width:calc(100% - 41px) !important;')"
        [class.ng-invalid]="fControl?.invalid"
        [class.ng-dirty]="fControl?.dirty"
      />
      <ng-container *ngIf="floatLabel; then labelTemplate"></ng-container>
      <ng-template #labelTemplate>
        <label
          class="md:w-12rem md:max-w-12rem md:min-w-12rem w-full mr-1 mb-1 input-field-label"
          *ngIf="label && !instanceInFormRepeater"
          >{{ label }}<span *ngIf="isControlRequired" class="text-red-500"> *</span></label
        >
      </ng-template>
      <div
        (click)="openEditModal()"
        class="inner-absolute-input-value p-inputtext flex flex-column gap-2"
        style="background: transparent; width: calc(100% - (41px)); position: absolute; height: 100%"
      >
        <app-code-navigation
          *ngIf="editModalControl?.value"
          [showTooltip]="true"
          [data]="editModalControl?.value"
        ></app-code-navigation>
      </div>
      <button
        *ngIf="!fControl?.disabled"
        type="button"
        pButton
        (click)="openEditModal()"
        class="white-space-nowrap"
        [icon]="'pi pi-pencil'"
      ></button>
    </span>
    <ng-container *ngIf="control?.dirty">
      <small class="p-error p-invalid" *ngIf="control?.hasError('required')">{{ label }} {{ 'is Required' }}</small>
      <small class="p-error p-invalid" *ngIf="customError && control?.invalid">{{ customErrorLabel }}</small>
    </ng-container>
    <small class="text-500 block" *ngIf="helpText">{{ helpText }}</small>
    <ng-container *ngIf="editModalControl?.dirty">
      <small class="p-error p-invalid" *ngIf="editModalControl?.hasError('required')"
        >{{ label }} {{ 'is Required' }}</small
      >
    </ng-container>
  </div>
</ng-template>

<p-dialog
  [header]="label"
  [(visible)]="editModalVisible"
  [modal]="true"
  [style]="{ width: '80vw' }"
  [draggable]="false"
  [resizable]="true"
>
  <ng-container *ngIf="editModalVisible; then inlineTemplate"></ng-container>
  <div class="w-full flex flex-row justify-content-end">
    <button
      *ngIf="!fControl?.disabled"
      type="button"
      pButton
      (click)="fControl?.patchValue(inlineControl?.getRawValue()); editModalVisible = false"
      label="Ok"
      class="white-space-nowrap w-fit"
      [icon]="'pi pi-pencil'"
    ></button>
  </div>
</p-dialog>
