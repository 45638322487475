<p-table
  class="w-full fields-table"
  [value]="control?.value"
  [columns]="cols"
  [reorderableColumns]="false"
  responsiveLayout="scroll"
  (onRowReorder)="updateControl($event)"
>
  <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
    <tr [pReorderableRow]="index" class="my-2">
      <div class="flex flex-row mt-4 gap-2">
        @if (viewMode != 'view') {
          <span class="pt-1 pi pi-bars" pReorderableRowHandle></span>
        }
        <app-code-navigation [data]="rowData"></app-code-navigation>
      </div></tr
  ></ng-template>
</p-table>
