<app-dashlet [styleClass]="styleClass" [config]="{ title: title }">
  <ng-content></ng-content>
  @if (headerTemplate) {
    <ng-template let-config #titleTemplate>
      <ng-container *ngTemplateOutlet="headerTemplate; context: { $implicit: config }"></ng-container>
    </ng-template>
  }
  <ul class="link-list m-0">
    @for (link of cardContent?.linkFields; track link) {
      <li>
        <a [href]="link?.link"
          ><span class="link-content">
            @if (link?.icon) {
              <i [class]="link?.icon"> </i>
            }
            {{ link?.title }}</span
          >
          <i class="external-icon pi pi-external-link"></i
        ></a>
      </li>
    }
  </ul>
  <!-- <div class="flex flex-column gap-1 flex-wrap">
    @for (link of cardContent?.linkFields; track link) {
      <app-link-view class="max-w-full min-w-0" [href]="link.link" [icon]="link.icon">{{ link.title }}</app-link-view>
    }
  </div> -->
</app-dashlet>
