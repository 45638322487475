import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import {
  DataTypeEnum,
  DynamicComponentBase,
  IAction,
  IViewMode,
  MessageKeys,
  QuestionnaireAnswerDto,
  QuestionnaireQuestionsDto,
  getDataKeyValueFormat,
} from '@shared/classes';
import { AssessmentFieldPopupComponent } from '@shared/components/components/assessment-components/assessment-field-popup/assessment-field-popup.component';
import { AppDialogService, ToastService, ViewModeService } from '@shared/services';
import { QuestionnaireDataService } from 'app/modules/questions/service/data/questionnaire-data.service';
import { QuestionsValueDataService } from 'app/modules/questions/service/data/questionnaire-value-data.service';

@Component({
  selector: 'app-questionnaire-answers-form',
  templateUrl: './questionnaire-answers-form.component.html',
  styleUrls: ['./questionnaire-answers-form.component.scss'],
})
export class QuestionnaireAnswersFormComponent extends DynamicComponentBase implements OnInit {
  dataType: DataTypeEnum = DataTypeEnum.Text;
  scoredType = QuestionnaireQuestionsDto.ScoringTypeEnum.Scored;

  @Output() onFocus: EventEmitter<any> = new EventEmitter();
  @Output() isValid: EventEmitter<any> = new EventEmitter();
  @Input() set forceValidation(value) {
    if (this.answersControl)
      this.forceValidationFunc(this.answersControl);
  }
  loading: Boolean = true;
  questions = [];
  questionnaire: QuestionnaireQuestionsDto;
  @Input() viewModeOnly: boolean = false;
  @Input() campaignCode;
  private _questionnaireCode;
  @Input() set questionnaireCode(questionnaireCode: string) {
    this._questionnaireCode = questionnaireCode;
    this.getQuestionnaire();
  }
  get questionnaireCode() {
    return this._questionnaireCode;
  }
  questionnaireValue: QuestionnaireAnswerDto;
  private _questionnaireValueCode;
  @Input() set questionnaireValueCode(questionnaireValueCode: string) {
    this._questionnaireValueCode = questionnaireValueCode;
    this.getQuestionnaireValue();
    // this.placeholderControl?.patchValue(questionnaireValueCode ? 'Check Questionnaire': 'Answer Questionnaire')
  }
  get questionnaireValueCode() {
    return this._questionnaireValueCode;
  }
  placeholderControl = new FormControl('Answer Questionnaire');
  placeholderCheckControl = new FormControl('Check Questionnaire');
  isLockedForEdit: boolean = false;
  answersControl = new FormArray([]);
  @Input() saveQuestionnaireAction: IAction = {
    id: 2,
    label: 'Save',
    buttonType: 'button',
    command: this.saveQuestionnaire.bind(this),
    icon: 'pi pi-save',
    // passEvent: true,
  };
  @Output() onSaveQuestionnaire: EventEmitter<QuestionnaireAnswerDto> = new EventEmitter();
  constructor(
    private appDialogService: AppDialogService,
    private questionnaireService: QuestionnaireDataService,
    private questionnaireValueService: QuestionsValueDataService,
    public viewModeService: ViewModeService,
    private toastService: ToastService
  ) {
    super();
  }

  ngOnInit(): void {
    this.answersControl.statusChanges.subscribe(x => {
      this.isValid.emit(x);
    })
  }
  onDestroy(): void {
    if (this.questionnaireValueCode && this.isLockedForEdit) {
      this.questionnaireValueService.releaseLock(this.questionnaireValueCode).subscribe((res) => { });
      this.isLockedForEdit = false;
    }
  }

  forceValidationFunc(form: AbstractControl) {
    if (form instanceof FormGroup || form instanceof FormArray) {
      for (const inner in form.controls) {
        const control = form.get(inner);
        control && this.forceValidationFunc(control);
      }
    } else {
      form.markAsDirty();
      form.markAsTouched();
      form.updateValueAndValidity();
    }
  }
  setInputOptions() { }
  saveQuestionnaire(event) {
    if (this.answersControl.valid) {
      let data: QuestionnaireAnswerDto = {
        answers: this.answersControl.value,
        // code: null,
        // id:  this.questionnaireValue?.id,
        questionnaire: this.questionnaire?.code,
        // questionnaireCode: this.questionnaire?.code,
        questionnaireType: this.questionnaire?.type,
        campaign: this.campaignCode
        // questionnaireName: this.questionnaire?.name,
      };
      this.questionnaireValueService.create(getDataKeyValueFormat(data)).subscribe((res) => {
        this.onSaveQuestionnaire.emit(res?.data);
      });
    } else {
      this.toastService.warn(MessageKeys.invalidAction, 'Please fill all forms correctly');
    }
  }
  onFocusChange(event) {
    this.onFocus.emit(event);
  }
  onEditQuestionnaire() {
    this.appDialogService.showDialog(
      AssessmentFieldPopupComponent,
      'Questionnaire',
      (e) => {
        if (e) {
          this.control.patchValue(e);
        }
      },
      {
        data: {
          questions: this.questions,
          questionnaire: this.questionnaire,
          questionnaireValue: this.control?.value
            ? this.control?.value?.answers
            : this.questionnaireValue?.answers
              ? this.questionnaireValue?.answers
              : this.questionnaireValue,
          viewModeOnly: this.viewModeOnly,
        },
      }
    );
  }
  getQuestionnaire() {
    if (!this.questionnaireCode) {
      this.loading = false;
      return;
    }
    this.loading = true;
    this.questionnaireService.getByIdOrCode<QuestionnaireQuestionsDto>(this.questionnaireCode).subscribe({
      next: (res) => {
        this.loading = false;
        this.questions = res.data.questions;
        this.questionnaire = res.data;
      },
      error: (err) => {
        this.loading = false;
      },
    });
  }
  getQuestionnaireValue() {
    if (!this.questionnaireValueCode) {
      this.loading = false;
      return;
    }
    this.loading = true;
    this.isLockedForEdit = this.fieldViewMode != 'view';
    (this.fieldViewMode != 'view'
      ? this.questionnaireValueService.getByIdOrCodeForEdit(this.questionnaireValueCode)
      : this.questionnaireValueService.getByIdOrCode(this.questionnaireValueCode)
    ).subscribe({
      next: (res) => {
        this.loading = false;
        this.questionnaireValue = res.data;
        if (!this.questionnaireCode) {
          this.questionnaireCode = this.questionnaireValue.questionnaire;
        }
      },
      error: (err) => {
        this.loading = false;
      },
    });
  }

  get fieldViewMode(): IViewMode {
    return this.viewModeOnly ? 'view' : this.viewModeService.viewMode;
  }
  onEditModalInputKeyDown(event) {
    if (event.key === 'Enter' || event.key === ' ') {
      // Enter or Space key was pressed
      this.onEditQuestionnaire();
    }
  }
}
