import { Component, Input, OnInit } from '@angular/core';
import { DataTypeEnum, DynamicComponentBase, IColumn } from '@shared/classes';

@Component({
  selector: 'app-reorder-selector',
  templateUrl: './reorder-selector.component.html',
  styleUrl: './reorder-selector.component.scss'
})
export class ReorderSelectorComponent extends DynamicComponentBase implements OnInit {

  cols: IColumn[] = [
    // { key: 'name', name: 'Name', dataType: DataTypeEnum.Text },
    // { key: "key", name: "Key", dataType: DataTypeEnum.Text },
    // { key: "label", name: "Label", dataType: DataTypeEnum.Text },
    { key: 'code', name: 'id', dataType: DataTypeEnum.CodeLink },
  ];

  @Input() items;

  constructor() {
    super();
  }



  ngOnInit(): void {

  }

  setInputOptions() {
  }

  updateControl(event) {
    let list = [...this.control?.value ?? []];
    // [list[event?.dragIndex], list[event?.dropIndex]] = [list[event?.dropIndex], list[event?.dragIndex]];
    this.items = [...list];
    this.control.patchValue(list);
  }
}
