<app-base-page-item
  [data]="data"
  [sourceCode]="itemId"
  [hasEditChanges]="hasEditChanges$ | async"
  [fromType]="fromType"
  [showRelations]="true"
  [showViewSwitch]="editMode && viewModeService.viewMode != 'create'"
  (onViewModeChange)="onViewModeChange($event)"
  [moduleKeyword]="moduleKeyword"
>
  <ng-container page-header>
    <h4 class="mb-0">
      {{ 'general.actions.' + (editMode ? viewModeService.viewMode : 'add') | translate }}
      {{
        'modules.' +
          routeToLocaleCase(ModuleKeywordRootPath[this.moduleKeyword]) +
          '.' +
          toCamelCase(this.moduleKeyword) +
          '.' +
          toCamelCase(this.moduleKeyword) | translate
      }}
    </h4>
  </ng-container>
  <app-general-record-form
    (formSubmit)="onSubmit($event)"
    (formSubmitSave)="onSubmit($event, 'PAGE')"
    (formSubmitInPlace)="onSubmit($event, 'NONE')"
    (cancelEvent)="onCancel($event)"
    [resetForm]="resetFormTrigger"
    (valueEditChanged)="valueEditChanged($event)"
    [setModuleKeyword]="moduleKeyword"
    [formData]="data"
  >
  </app-general-record-form>
</app-base-page-item>
